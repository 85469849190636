<template>
  <div style="height: 100%" id="container">
    <div class="seachBox">
      <div
        :class="['item', { active: actives === '社区' }]"
        @click="active('社区')"
      >
        <div>社区楼栋数</div>
        <span></span>
      </div>
      <div
        :class="['item', { active: actives === '小区' }]"
        @click="active('小区')"
      >
        <div>小区楼栋数</div>
        <span></span>
      </div>
      <div
        :class="['item', { active: actives === '楼栋' }]"
        @click="active('楼栋')"
      >
        <div>楼栋分布</div>
        <span></span>
      </div>
    </div>
    <div class="detail" v-if="detailShow">
      <detaipage
        :placeDetail="placeDetail"
        @closeDetail="closeDetail"
      ></detaipage>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import detaipage from "./components/detailPage.vue";
import api from "./api";

export default {
  components: { detaipage },
  data() {
    return {
      api,
      map: null,
      placeDetail: null,
      detailShow: false,
      actives: "社区",
      queryArr: [],
    };
  },
  watch: {
    queryArr(value) {
      this.queryArr = this.$store.state.item.latlog;
    },
  },

  methods: {
    initAMap() {
      const self = this; // 存储当前的this
      AMapLoader.load({
        key: "6636defcef28a2bf1f1043f154835db6", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",
        plugins: ["AMap.MouseTool"],
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
      })
        .then((AMap) => {
          self.map = new AMap.Map("container", {
            // 设置地图容器id
            viewMode: "2D", // 是否为3D地图模式
            zoom: 12, // 初始化地图级别
            terrain: true, //开启地形图
            center: [117.303216, 31.803165], // 初始化地图中心点位置
          });
          self.community();
          // 监听zoom层级
          self.map.on("zoomchange", function (e) {
            const zoomLevel = self.map.getZoom();
            if (zoomLevel > 12 && zoomLevel < 12.3) {
              self.$nextTick(() => {
                self.map.clearMap();
                self.community();
              });
            } else if (zoomLevel > 13 && zoomLevel < 13.3) {
              self.$nextTick(() => {
                self.map.clearMap();
                self.housing();
              });
            } else if (zoomLevel > 15 && zoomLevel < 15.3) {
              self.$nextTick(() => {
                self.map.clearMap();
                self.building();
              });
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    community() {
      const groupedItems = [];
      if (!this.queryArr.length) {
        this.queryArr = this.$store.state.item.latlog;
      }
      this.queryArr.forEach((item) => {
        const existingGroup = groupedItems.find(
          (group) => group[0].gridPid === item.gridPid
        );
        if (existingGroup) {
          existingGroup.push(item);
        } else {
          groupedItems.push([item]);
        }
      });
      if (!groupedItems[0][0].latitude && !groupedItems[0][0].longitude) {
        return;
      }
      const lnglat = new AMap.LngLat(
        groupedItems[0][0].latitude,
        groupedItems[0][0].longitude
      );
      this.map.setCenter(lnglat);
      groupedItems.forEach((item) => {
        var text = new AMap.Text({
          text: `<div style='display: flex;flex-direction: column;align-items: center;'> <div>${item[0].gridPname}</div> <div>${item.length}</div> </div>`,
          anchor: "center", //设置文本标记锚点位置
          draggable: false, //是否可拖拽
          cursor: "pointer", //指定鼠标悬停时的鼠标样式。
          angle: 0, //点标记的旋转角度
          style: {
            //设置文本样式，Object 同 css 样式表
            "margin-bottom": "10px",
            "border-radius": "50%",
            "background-color": "#556BFF",
            width: "0.5rem",
            height: "0.5rem",
            "font-size": "0.08rem",
            "font-weight": "700",
            color: "#ffff",
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
          },
          position: [item[0].latitude, item[0].longitude], //点标记在地图上显示的位置
        });
        text.on("click", (e) => {
          this.map.setZoom(13.2);
        });
        text.setMap(this.map); //将文本标记设置到地图上
      });
    },
    housing() {
      const groupedItems = [];
      this.queryArr.forEach((item) => {
        const existingGroup = groupedItems.find(
          (group) => group[0].gridId === item.gridId
        );
        if (existingGroup) {
          existingGroup.push(item);
        } else {
          groupedItems.push([item]);
        }
      });
      if (!groupedItems[0][0].latitude && !groupedItems[0][0].longitude) {
        return;
      }
      const lnglat = new AMap.LngLat(
        groupedItems[0][0].latitude,
        groupedItems[0][0].longitude
      );
      this.map.setCenter(lnglat);
      groupedItems.forEach((item) => {
        var text = new AMap.Text({
          text: `<div style='display: flex;flex-direction: column;align-items: center;'> <div>${item[0].gridName}</div> <div>${item.length}</div> </div>`,
          anchor: "center", //设置文本标记锚点位置
          draggable: false, //是否可拖拽
          cursor: "pointer", //指定鼠标悬停时的鼠标样式。
          angle: 0, //点标记的旋转角度
          style: {
            //设置文本样式，Object 同 css 样式表
            "margin-bottom": "10px",
            "border-radius": "10px",
            "background-color": "#6b7efd",
            width: "0.5rem",
            height: "0.25rem",
            "font-size": "0.07rem",
            color: "#ffff",
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
          },
          position: [item[0].latitude, item[0].longitude], //点标记在地图上显示的位置
        });

        text.on("click", (e) => {
          this.map.setZoom(15.2);
        });
        text.setMap(this.map);
      });
    },
    building() {
      var url = require("@/assets/community/mapIcon/lou.png");
      this.queryArr.forEach((item) => {
        const marker = new AMap.Marker({
          position: new AMap.LngLat(item.latitude, item.longitude),
          icon: url,
          offset: new AMap.Pixel(-13, -30),
          extData: item,
        });
        this.map.add(marker);
        marker.on("click", (e) => {
          const extData = e.target.getExtData(); // 获取 extData
          this.placeDetail = extData;
          console.log(this.placeDetail);
          this.detailShow = true;
        });
      });
    },
    closeDetail() {
      this.detailShow = false;
    },
    active(e) {
      this.actives = e;
      if (e === "社区") {
        this.map.setZoom(12.2);
      } else if (e === "小区") {
        this.map.setZoom(13.2);
      } else {
        this.map.setZoom(15.2);
      }
    },
    async getQueryAll() {
      try {
        const res = await api.queryAll();
        this.queryArr = res.data;
        this.$store.commit("item/getLatlog", res.data);
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {},

  mounted() {
    this.initAMap();
    this.getQueryAll();
  },
};
</script>
<style lang='less' scoped>
#container {
  position: relative;
  border-radius: 8px;
  .seachBox {
    position: absolute;
    top: 1%;
    left: 1%;
    height: 6%;
    width: 20%;
    border-radius: 7px;
    background-color: #ffff;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 15px 0px rgba(143, 154, 165, 0.4);
    .item {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        height: 70%;
        width: 100%;
        font-size: 0.07rem;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      span {
        height: 5%;
        width: 30%;
        background-color: #ffffff;
        border-radius: 15px;
      }
    }
  }

  .detail {
    position: absolute;
    top: 2%;
    right: 2%;
    width: 30%;
    z-index: 999;
  }
}
.active {
  color: #2A5CFF !important;
  span {
    background-color: #2A5CFF !important;
  }
}

.prefix {
  height: 80%;
}
</style>